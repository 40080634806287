import { myEnterprise } from "./menu-my-enterprise";

const menuData = [
  {
    category: false,
    title: "Painel",
    key: "dashboards",
    icon: "home",
    url: "/main/dashboard",
    single: true,
    visible: ["admin", "borrower", "provider", "validator"],
  },
  {
    title: "Usuários",
    category: false,
    roles: ["Maat"],
    key: "users",
    icon: "users-alt",
    url: "/users/list",
    single: true,
    visible: ["admin", "borrower", "provider"],
  },
  {
    title: "Principais Clientes",
    category: false,
    key: "clients",
    roles: ["Maat"],
    icon: "user-check",
    visible: ["admin"],
    children: [
      {
        title: "Tomadores",
        key: "borrower",
        icon: "user-nurse",
        url: "/borrower/list",
        visible: ["admin", "borrower"],
      },
      {
        title: "Prestadores",
        key: "provider",
        icon: "hard-hat",
        url: "/provider/list",
        visible: ["admin", "borrower"],
      },
    ],
  },
  {
    title: "Homologações",
    key: "homologacao",
    icon: "clipboard-notes",
    url: "/homologation/list",
    single: true,
    visible: ["admin", "borrower"],
  },
  {
    title: "Meus Serviços",
    key: "services-enterprise",
    url: "/service/list",
    icon: "constructor",
    single: true,
    visible: ["provider"],
  },
  myEnterprise,

  {
    title: "Sistema",
    key: "sistema-list",
    url: "/",
    icon: "setting",
    visible: ["admin", "borrower", "provider", "validator"],
    children: [
      {
        title: "Aparência",
        key: "apperence",
        url: "/system/apperence",
        icon: "palette",
        visible: ["admin", "borrower", "provider", "validator"],
      },
      {
        title: "Template de Indicadores",
        key: "template-indicators",
        url: "/system/template-indicators/list",
        icon: "briefcase",
        visible: ["admin"],
      },
      {
        title: "Permissões",
        key: "permissions",
        url: "/system/permissions",
        icon: "lock",
        visible: ["admin"],
      },
      {
        title: "Questionários",
        key: "questions",
        url: "/system/questions/list",
        icon: "question-circle",
        visible: ["admin"],
      },
      {
        title: "Pesquisa de satisfação",
        key: "satisfactionSurvey",
        url: "/system/satisfaction-survey/list",
        icon: "comment-chart-line",
        visible: ["admin"],
      },
      {
        title: "Documentos",
        key: "documents",
        url: "/system/documents/list",
        icon: "file-copy-alt",
        visible: ["admin"],
      },
      {
        title: "Logs de Auditoria",
        key: "logs",
        url: "/system/logs/filter",
        icon: "file-search-alt",
        visible: ["admin"],
      },
    ],
  },
];
export { menuData };
