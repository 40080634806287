export const modulesRoutes = {
  path: "/homologation/:homologation_id/service/:service_id/:user_role/",
  redirect: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
  meta: {
    breadcrumbs: {
      title: "Selecionar módulo",
      label: "Módulos",
      icon: "columns-gap",
      destination: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
    },
    transition: "fade",
  },

  component: () => import("@/pages/Homologation/Services/Modules/"),
  children: [
    {
      path:
        "/homologation/:homologation_id/service/:service_id/:user_role/financial/balance-sheet",
      meta: {
        breadcrumbs: {
          title: "Financeiro",
          label: "Financeiro",
          icon: "cash-stack",
          back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
          destination: "#",
        },
        transition: "fade",
      },

      component: () => import("@/pages/Homologation/Services/Modules/Financial/"),
      children: [
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/financial/balance-sheet",
          meta: {
            breadcrumbs: {
              title: "Financeiro - Lançamentos Financeiros",
              label: "Lançamento de Rendimentos",
              icon: "cash-stack",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Financial/BalanceSheet"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/financial/income",
          meta: {
            breadcrumbs: {
              title: "Financeiro - Indicadores Financeiros",
              label: "Indicadores Financeiros",
              icon: "cash-stack",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Financial/Income"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/financial/fundofin",
          meta: {
            breadcrumbs: {
              title: "Financeiro - Avaliação FundoFin",
              label: "Avaliação FundoFin",
              icon: "cash-stack",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Financial/FundoFin"),
        },
      ],
    },
    {
      path:
        "/homologation/:homologation_id/service/:service_id/:user_role/operational-technician/",
      meta: {
        breadcrumbs: {
          title: "Técnico operacional",
          label: "Técnico operacional",
          icon: "hammer",
          back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
          destination: "#",
        },
        transition: "fade",
      },
      component: () =>
        import("@/pages/Homologation/Services/Modules/OperationalTechnician/"),
      children: [
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/operational-technician/location-base",
          meta: {
            breadcrumbs: {
              title: "Localização - Base Operacional",
              label: "Localização - Base Operacional",
              icon: "map",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import(
              "@/pages/Homologation/Services/Modules/OperationalTechnician/LocationBase/"
            ),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/operational-technician/economic-activity",
          meta: {
            breadcrumbs: {
              title: "Atividade Econômica",
              label: "Atividade Econômica",
              icon: "cash",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import(
              "@/pages/Homologation/Services/Modules/OperationalTechnician/EconomicActivity.vue"
            ),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/operational-technician/company-size",
          meta: {
            breadcrumbs: {
              title: "Porte da empresa",
              label: "Porte da empresa",
              icon: "building",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import(
              "@/pages/Homologation/Services/Modules/OperationalTechnician/CompanySize.vue"
            ),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/operational-technician/insurance-coverage",
          meta: {
            breadcrumbs: {
              title: "Seguros e cobertura de riscos",
              label: "Seguros e cobertura de riscos",
              icon: "lock",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import(
              "@/pages/Homologation/Services/Modules/OperationalTechnician/InsuranceCoverage"
            ),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/operational-technician/technical-capacity",
          meta: {
            breadcrumbs: {
              title: "Pesquisa de Satisfação",
              label: "Pesquisa de Satisfação",
              icon: "wallet-fill",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import(
              "@/pages/Homologation/Services/Modules/OperationalTechnician/TechnicalCapacity/"
            ),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/operational-technician/questionaire",
          meta: {
            breadcrumbs: {
              title: "Questionário técnico",
              label: "Questionário",
              icon: "wallet-fill",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import(
              "@/pages/Homologation/Services/Modules/OperationalTechnician/Questionaire/"
            ),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/operational-technician/fundotercoper",
          meta: {
            breadcrumbs: {
              title: "Avaliação Fundotercoper",
              label: "Fundotercoper",
              icon: "chart",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import(
              "@/pages/Homologation/Services/Modules/OperationalTechnician/Fundotercoper"
            ),
        },
      ],
    },
    // Legal Trabalhista
    {
      path:
        "/homologation/:homologation_id/service/:service_id/:user_role/legal-labor/",
      meta: {
        breadcrumbs: {
          title: "Legal Trabalhista",
          label: "Legal Trabalhista",
          icon: "shield",
          back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
          destination: "#",
        },
        transition: "fade",
      },
      component: () => import("@/pages/Homologation/Services/Modules/Legal/"),
      children: [
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/legal-labor/human-resources",
          meta: {
            breadcrumbs: {
              title: "Gestão de Pessoas",
              label: "Gestão de Pessoas",
              icon: "person-badge",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Legal/HumanResources.vue"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/legal-labor/labor-lawsuits",
          meta: {
            breadcrumbs: {
              title: "Processos Trabalhistas",
              label: "Processos Trabalhistas",
              icon: "journal-medical",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Legal/LaborLawsuits/"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/legal-labor/system-management",
          meta: {
            breadcrumbs: {
              title: "Sistemas de informação e Gestão",
              label: "Sistemas de informação e Gestão",
              icon: "cpu",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Legal/InformationSystems.vue"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/legal-labor/questionaire",
          meta: {
            breadcrumbs: {
              title: "Pesquisa de satisfação colaboradores",
              label: "Pesquisa de satisfação colaboradores",
              icon: "people",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Legal/Questionaire/"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/legal-labor/fundotercoper",
          meta: {
            breadcrumbs: {
              title: "Avaliação Fundotercoper",
              label: "Fundotercoper",
              icon: "chart",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Legal/Fundotercoper"),
        },
      ],
    },
    // --- Legal Trabalhista
    {
      path:
        "/homologation/:homologation_id/service/:service_id/:user_role/regularity/",
      meta: {
        breadcrumbs: {
          title: "Regularidade",
          label: "Regularidade",
          icon: "shield",
          back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
          destination: "#",
        },
        transition: "fade",
      },
      component: () => import("@/pages/Homologation/Services/Modules/Regularity/"),
      children: [
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/regularity/credit-inquiry",
          meta: {
            breadcrumbs: {
              title: "Regularidade - Consulta de Crédito",
              label: "Consulta de Crédito",
              icon: "cash",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Regularity/CreditInquiry"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/regularity/certifications",
          meta: {
            breadcrumbs: {
              title: "Regularidade - Documentos",
              label: "Documentos",
              icon: "journal-medical",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Regularity/Certifications"),
        },
        {
          path:
            "/homologation/:homologation_id/service/:service_id/:user_role/regularity/certifications-iso",
          meta: {
            breadcrumbs: {
              title: "Regularidade - Certificações ISO",
              label: "Certificações ISO",
              icon: "journal-medical",
              back: `/homologation/:homologation_id/service/:service_id/:user_role/validation`,
              destination: "#",
            },
            transition: "fade",
          },
          component: () =>
            import("@/pages/Homologation/Services/Modules/Regularity/CertificationsIso"),
        },
      ],
    },
  ],
};
